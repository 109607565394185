import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client.vue')
  },
  //  {
  //    path: '/turestaurante',
  //    name: 'turestaurante',
  //    component: () => import(/* webpackChunkName: "about" */ '../views/Client.vue')
  //  },
  //  {
  //    path: '/restaurant',
  //    name: 'restaurant',
  //    component: () => import(/* webpackChunkName: "about" */ '../views/Restaurant.vue')
  //  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/termsAndConditions',
    name: 'termsAndConditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/unsubscribeGoogle',
    name: 'unsubscribeGoogle',
    component: () => import(/* webpackChunkName: "about" */ '../views/UnsubscribeGoogle.vue')
  },
  {
    path: '/elimina-tus-datos',
    name: 'instructions',
    component: () => import(/* webpackChunkName: "about" */ '../views/Instructions.vue')
  }
//  {
//    path: '/profile',
//    name: 'profile',
//    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
//  },
//  {
//    path: '/payment',
//    name: 'payment',
//    component: () => import(/* webpackChunkName: "about" */ '../views/Payment.vue')
//  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
