import precomproTypes from '@/types/precompro';
import authTypes from '@/types/auth';
import Api from '@/helpers/api';
import { Base64 } from 'js-base64';

const state = {
  profileTab: 'profile', // muestra tab perfil
  loader: false, // muestra procesando petición
  sendReservation: false, // muestra todos los restaurantes
  showVendors: true, // muestra todos los restaurantes
  editReservation: [], // array para la edicion de reserva
  reservationVendors: [], // array de restaurantes
  reservationByUser: [], // array de reservas de usuario
  vendor: [], // array de restaurante
  gallery: [], // array de galeria del restaurante
  services: [], // array de servicios del restaurante
  reservation: [], // array de datos de reserva
  banks: [], // array de bancos
  reservationRecommended: [], // array de recomendados
  reservationAvailability: [], // array de disponibilidad por restaurantes
  reservationAvailabilityVendor: [], // array de disponibilidad por restaurante elejido
  reservationTimeAtention: [], // array de horarios
  reservationHours: null, // dia y gora de la reserva
  reservationDate: null, // dia de la reserva
  reservationPeople: 2, // cantidad de personas
  reservationShowSearchButton: false, // habilita el boton de busqueda
  reservationShowHours: false, // habilita que se muestren las horas
  reservationShowProcessingHours: false, // habilita que se muestren el loading de las horas
  reservationSearch: '', // contiene el texto de filtrado para restaurantes
  reservationSearchingAvailability: false, // habilita la busqueda de opciones de reserva
  showModalReservationConfirm: false, // mustra modal de confirmacion de reserva
  showModalPolitica: false, // mustra modal de politica de cancelacion o modificacion
  showAddPay: false, // agregar tarjeta
  source: '', // contiene el origen de la consulta
  paymentType: '', // contiene el tipo de pago PSE o TC
  selectHourOk: false, // se ha seleccionado una hora ?
  idCity: '', // Id de la ciudad para realizar los filtros
  isSearch: false, // Flag para saber si se realizo una busqueda
  selectedZone: false, // Zonas seleccionadas
  selectedDate: null, // Fecha seleccionada
  isAvalability: false,
  selectedTypeReservation: 'Normal'
};
const actions = {
  [precomproTypes.actions.getCities]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().get('precompro/cities')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.setDeleteReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/reservation/state', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchOptions]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/searchOptions', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchComments]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/searchComments', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.updateVendor]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/update/vendor', data.data)
        .then(response => {
          if (response.data.code === 200) {
            commit(precomproTypes.mutations.setVendor, response.data.data);
          }
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.updateReservationState]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Api().get('precompro/update/reservationState')
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.cancelReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().put('precompro/cancelReservation/' + data.id)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.updateReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/updateReservation', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.reservationByUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/reservationByUser', data.data)
        .then(response => {
          window.localStorage.setItem('precompro_reservation_by_user', Base64.encode(JSON.stringify(response.data)));
          commit(precomproTypes.mutations.setReservationByUser);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.registerPay]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/payAdd', data)
        .then(response => {
          if (response.data.code === 200) {
            window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(response.data.payments)));
            commit(authTypes.mutations.setPayment);
          }
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.deletePay]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/deletePay', data)
        .then(response => {
          if (response.data.code === 200) {
            window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(response.data.payments)));
            commit(authTypes.mutations.setPayment);
          }
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.preReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/preReservation', data.data)
        .then(response => {
          if (response.data.code === 200) {
            data.data.futureReservationsId = response.data.futureReservations;
            data.data.vendorAvailability = response.data.vendorAvailability;
            window.localStorage.setItem('precompro_reservation_future', Base64.encode(JSON.stringify(response.data.futureReservations)));
            window.localStorage.setItem('precompro_reservation_availability', Base64.encode(JSON.stringify(response.data.vendorAvailability)));
            commit(precomproTypes.mutations.setReservation, data.data);
          }
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.getBanks]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().get('vendors/pse/banks/' + data.data.vendorId)
        .then(response => {
          commit(precomproTypes.mutations.setBanks, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.confirmReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/confirmReservation', data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchRecommended]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Api().get('precompro/recommended')
        .then(response => {
          commit(precomproTypes.mutations.setReservationRecommended, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchVendors]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Api().get('precompro/vendors')
        .then(response => {
          commit(precomproTypes.mutations.setReservationVendors, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchAvailabilityVendor]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().get('vendors/availability/time/' + data.data.vendorId + '/' + data.data.timestamp + '/0/' + data.data.people + '/' + data.data.zone + '/' + data.data.typeReservation + '/null')
        .then(response => {
          commit(precomproTypes.mutations.setReservationAvailabilityVendor, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.searchAvailability]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/availability', data.data)
        .then(response => {
          commit(precomproTypes.mutations.setReservationAvailability, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.getGallery]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/gallery', data.data)
        .then(response => {
          commit(precomproTypes.mutations.setGallery, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.getServices]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/services', data.data)
        .then(response => {
          commit(precomproTypes.mutations.setServices, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [precomproTypes.actions.getZones]: (state, data) => {
    return new Promise((resolve, reject) => {
      Api().post('dashboard/section', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => { reject(error); });
    });
  },
  [precomproTypes.actions.contact]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('precompro/contact', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  [precomproTypes.getters.selectHourOk]: (state) => {
    return state.selectHourOk;
  },
  [precomproTypes.getters.showAddPay]: (state) => {
    return state.showAddPay;
  },
  [precomproTypes.getters.editReservation]: (state) => {
    return state.editReservation;
  },
  [precomproTypes.getters.showModalPolitica]: (state) => {
    return state.showModalPolitica;
  },
  [precomproTypes.getters.showModalReservationConfirm]: (state) => {
    return state.showModalReservationConfirm;
  },
  [precomproTypes.getters.reservationByUser]: (state) => {
    return state.reservationByUser;
  },
  [precomproTypes.getters.profileTab]: (state) => {
    return state.profileTab;
  },
  [precomproTypes.getters.banks]: (state) => {
    return state.banks;
  },
  [precomproTypes.getters.loader]: (state) => {
    return state.loader;
  },
  [precomproTypes.getters.paymentType]: (state) => {
    return state.paymentType;
  },
  [precomproTypes.getters.reservation]: (state) => {
    return state.reservation;
  },
  [precomproTypes.getters.services]: (state) => {
    return state.services;
  },
  [precomproTypes.getters.vendor]: (state) => {
    return state.vendor;
  },
  [precomproTypes.getters.showVendors]: (state) => {
    return state.showVendors;
  },
  [precomproTypes.getters.reservationSearchingAvailability]: (state) => {
    return state.reservationSearchingAvailability;
  },
  [precomproTypes.getters.reservationSearch]: (state) => {
    return state.reservationSearch;
  },
  [precomproTypes.getters.reservationShowProcessingHours]: (state) => {
    return state.reservationShowProcessingHours;
  },
  [precomproTypes.getters.reservationShowHours]: (state) => {
    return state.reservationShowHours;
  },
  [precomproTypes.getters.reservationTimeAtention]: (state) => {
    return state.reservationTimeAtention;
  },
  [precomproTypes.getters.reservationAvailability]: (state) => {
    return state.reservationAvailability;
  },
  [precomproTypes.getters.reservationAvailabilityVendor]: (state) => {
    return state.reservationAvailabilityVendor;
  },
  [precomproTypes.getters.reservationVendors]: (state) => {
    return state.reservationVendors;
  },
  [precomproTypes.getters.reservationRecommended]: (state) => {
    return state.reservationRecommended;
  },
  [precomproTypes.getters.reservationHours]: (state) => {
    return state.reservationHours;
  },
  [precomproTypes.getters.reservationDate]: (state) => {
    return state.reservationDate;
  },
  [precomproTypes.getters.reservationPeople]: (state) => {
    return state.reservationPeople;
  },
  [precomproTypes.getters.reservationShowSearchButton]: (state) => {
    return state.reservationShowSearchButton;
  },
  [precomproTypes.getters.gallery]: (state) => {
    return state.gallery;
  },
  [precomproTypes.getters.source]: (state) => {
    return state.source;
  },
  [precomproTypes.getters.sendReservation]: (state) => {
    return state.sendReservation;
  },
  [precomproTypes.getters.idCity]: (state) => {
    return state.idCity;
  },
  [precomproTypes.getters.isSearch]: (state) => {
    return state.isSearch;
  },
  [precomproTypes.getters.selectedZone]: state => state.selectedZone,
  [precomproTypes.getters.selectedDate]: state => state.selectedDate,
  [precomproTypes.getters.isAvalability]: state => state.isAvalability,
  [precomproTypes.getters.selectedTypeReservation]: state => state.selectedTypeReservation
};

const mutations = {
  [precomproTypes.mutations.setSelectHourOk]: (state, data) => {
    state.selectHourOk = data;
  },
  [precomproTypes.mutations.setShowAddPay]: (state, data) => {
    state.showAddPay = data;
  },
  [precomproTypes.mutations.setEditReservation]: (state, data) => {
    state.editReservation = data;
  },
  [precomproTypes.mutations.setShowModalPolitica]: (state, data) => {
    state.showModalPolitica = data;
  },
  [precomproTypes.mutations.setShowModalReservationConfirm]: (state, data) => {
    state.showModalReservationConfirm = data;
  },
  [precomproTypes.mutations.setProfileTab]: (state, tab) => {
    if (window.localStorage.getItem('precompro_profileTab')) {
      if (tab !== undefined) {
        window.localStorage.setItem('precompro_profileTab', Base64.encode(JSON.stringify(tab)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_profileTab')));
        state.profileTab = json;
      } else {
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_profileTab')));
        state.profileTab = json;
      }
    } else {
      if (tab !== undefined) {
        window.localStorage.setItem('precompro_profileTab', Base64.encode(JSON.stringify(tab)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_profileTab')));
        state.profileTab = json;
      } else {
        state.profileTab = 'profile';
      }
    }
  },
  [precomproTypes.mutations.setPaymentType]: (state, data) => {
    state.paymentType = data;
  },
  [precomproTypes.mutations.setReservationByUser]: (state, data) => {
    if (window.localStorage.getItem('precompro_reservation_by_user')) {
      if (data !== undefined) {
        window.localStorage.setItem('precompro_reservation_by_user', Base64.encode(JSON.stringify(data)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_by_user')));
        state.reservationByUser = json;
      } else {
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_by_user')));
        state.reservationByUser = json;
      }
    } else {
      if (data !== undefined) {
        window.localStorage.setItem('precompro_reservation_by_user', Base64.encode(JSON.stringify(data)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_by_user')));
        state.reservationByUser = json;
      } else {
        state.reservationByUser = [];
      }
    }
  },
  [precomproTypes.mutations.setVendor]: (state, vendor) => {
    if (window.localStorage.getItem('precompro_vendor_precompro')) {
      if (vendor !== undefined) {
        window.localStorage.setItem('precompro_vendor_precompro', Base64.encode(JSON.stringify(vendor)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_vendor_precompro')));
        state.vendor = json;
      } else {
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_vendor_precompro')));
        state.vendor = json;
      }
    } else {
      if (vendor !== undefined) {
        window.localStorage.setItem('precompro_vendor_precompro', Base64.encode(JSON.stringify(vendor)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_vendor_precompro')));
        state.vendor = json;
      } else {
        state.vendor = [];
      }
    }
  },
  [precomproTypes.mutations.setReservation]: (state, reservation) => {
    if (window.localStorage.getItem('precompro_reservation_precompro')) {
      if (reservation !== undefined) {
        window.localStorage.setItem('precompro_reservation_precompro', Base64.encode(JSON.stringify(reservation)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_precompro')));
        state.reservation = json;
      } else {
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_precompro')));
        state.reservation = json;
      }
    } else {
      if (reservation !== undefined) {
        window.localStorage.setItem('precompro_reservation_precompro', Base64.encode(JSON.stringify(reservation)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_reservation_precompro')));
        state.reservation = json;
      } else {
        state.reservation = [];
      }
    }
  },
  [precomproTypes.mutations.setBanks]: (state, data) => {
    state.banks = data;
  },
  [precomproTypes.mutations.setLoader]: (state, loader) => {
    state.loader = loader;
  },
  [precomproTypes.mutations.setGallery]: (state, gallery) => {
    state.gallery = gallery;
  },
  [precomproTypes.mutations.setServices]: (state, services) => {
    state.services = services;
  },
  [precomproTypes.mutations.setShowVendors]: (state, showVendors) => {
    state.showVendors = showVendors;
  },
  [precomproTypes.mutations.setReservationSearchingAvailability]: (state, searching) => {
    state.reservationSearchingAvailability = searching;
  },
  [precomproTypes.mutations.setReservationSearch]: (state, search) => {
    state.reservationSearch = search;
  },
  [precomproTypes.mutations.setReservationShowProcessingHours]: (state, showProcessing) => {
    state.reservationShowProcessingHours = showProcessing;
  },
  [precomproTypes.mutations.setReservationShowHours]: (state, showHours) => {
    state.reservationShowHours = showHours;
  },
  [precomproTypes.mutations.setReservationTimeAtention]: (state, times) => {
    state.reservationTimeAtention = times;
  },
  [precomproTypes.mutations.setReservationAvailability]: (state, availability) => {
    state.reservationAvailability = availability;
  },
  [precomproTypes.mutations.setReservationAvailabilityVendor]: (state, availability) => {
    state.reservationAvailabilityVendor = availability;
  },
  [precomproTypes.mutations.setReservationVendors]: (state, vendors) => {
    state.reservationVendors = vendors;
  },
  [precomproTypes.mutations.setReservationRecommended]: (state, recommended) => {
    state.reservationRecommended = recommended;
  },
  [precomproTypes.mutations.setReservationHours]: (state, hours) => {
    state.reservationHours = hours;
  },
  [precomproTypes.mutations.setReservationDate]: (state, date) => {
    state.reservationDate = date;
  },
  [precomproTypes.mutations.setReservationPeople]: (state, people) => {
    state.reservationPeople = people;
  },
  [precomproTypes.mutations.setReservationShowSearchButton]: (state, showSearch) => {
    state.reservationShowSearchButton = showSearch;
  },
  [precomproTypes.mutations.setSource]: (state, source) => {
    if (window.localStorage.getItem('precompro_vendor_source')) {
      if (source !== undefined) {
        window.localStorage.setItem('precompro_vendor_source', Base64.encode(source));
        const json = Base64.decode(window.localStorage.getItem('precompro_vendor_source'));
        state.source = json;
      } else {
        const json = Base64.decode(window.localStorage.getItem('precompro_vendor_source'));
        state.source = json;
      }
    } else {
      if (source !== undefined) {
        window.localStorage.setItem('precompro_vendor_source', Base64.encode(source));
        const json = Base64.decode(window.localStorage.getItem('precompro_vendor_source'));
        state.source = json;
      }
    }
  },
  [precomproTypes.mutations.setSendReservation]: (state, send) => {
    if (window.localStorage.getItem('precompro_sendReservation')) {
      if (send !== undefined) {
        window.localStorage.setItem('precompro_sendReservation', Base64.encode(JSON.stringify(send)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_sendReservation')));
        state.sendReservation = json;
      } else {
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_sendReservation')));
        state.sendReservation = json;
      }
    } else {
      if (send !== undefined) {
        window.localStorage.setItem('precompro_sendReservation', Base64.encode(JSON.stringify(send)));
        const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_sendReservation')));
        state.sendReservation = json;
      } else {
        state.sendReservation = false;
      }
    }
  },
  [precomproTypes.mutations.setIdCity]: (state, data) => {
    state.idCity = data;
  },
  [precomproTypes.mutations.setIsSearch]: (state, data) => {
    state.isSearch = data;
  },
  [precomproTypes.mutations.setSelectedZone] (state, data) {
    state.selectedZone = data;
  },
  [precomproTypes.mutations.setSelectedDate] (state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  [precomproTypes.mutations.setIsAvalability] (state, data) {
    state.isAvalability = data;
  }
};
export default {
  state,
  actions,
  getters,
  mutations
};
