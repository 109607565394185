import namespace from '@/utils/namespace';

export default namespace('auth', {
  getters: [
    'user',
    'payment',
    'userId',
    'logged',
    'showAuth',
    'typeModal'
  ],
  actions: [
    'login',
    'socialLogin',
    'register',
    'reset',
    'validateToken',
    'changedPassword',
    'logout',
    'updateUser',
    'refreshUser'
  ],
  mutations: [
    'setPayment',
    'setShowAuth',
    'setTypeModal',
    'setUser',
    'setLogged',
    'setUserId'
  ]
});
