import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langEs from '@/helpers/dialogs/es.js';
import langEn from '@/helpers/dialogs/en.js';
import LocalStorage from 'store';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: LocalStorage.get('precompro_lang') || 'es',
  fallbackLocale: 'es',
  messages: {
    es: langEs,
    en: langEn
  }
});

export default i18n;
