import namespace from '@/utils/namespace';

export default namespace('precompro', {
  getters: [
    'showAddPay',
    'editReservation',
    'reservationByUser',
    'profileTab',
    'loader',
    'paymentType',
    'reservation',
    'vendor',
    'showVendors',
    'reservationSearchingAvailability',
    'reservationSearch',
    'reservationShowProcessingHours',
    'reservationShowHours',
    'reservationTimeAtention',
    'reservationHours',
    'reservationDate',
    'reservationPeople',
    'reservationShowSearchButton',
    'reservationAvailability',
    'reservationRecommended',
    'reservationVendors',
    'gallery',
    'services',
    'banks',
    'reservationAvailabilityVendor',
    'showModalReservationConfirm',
    'source',
    'showModalPolitica',
    'sendReservation',
    'selectHourOk',
    'idCity',
    'isSearch',
    'selectedZone',
    'selectedDate',
    'isAvalability',
    'selectedTypeReservation'
  ],
  actions: [
    'updateVendor',
    'updateReservationState',
    'cancelReservation',
    'updateReservation',
    'reservationByUser',
    'deletePay',
    'registerPay',
    'preReservation',
    'getBanks',
    'confirmReservation',
    'searchAvailability',
    'searchAvailabilityVendor',
    'searchRecommended',
    'getGallery',
    'getServices',
    'searchVendors',
    'searchComments',
    'searchOptions',
    'setDeleteReservation',
    'getCities',
    'getZones',
    'selectedDate',
    'contact'
  ],
  mutations: [
    'setSelectHourOk',
    'setShowAddPay',
    'setEditReservation',
    'setShowModalReservationConfirm',
    'setShowModalPolitica',
    'setReservationByUser',
    'setProfileTab',
    'setBanks',
    'setLoader',
    'setPaymentType',
    'setReservation',
    'setVendor',
    'setShowVendors',
    'setReservationSearchingAvailability',
    'setReservationSearch',
    'setReservationShowProcessingHours',
    'setReservationShowHours',
    'setReservationTimeAtention',
    'setReservationHours',
    'setReservationDate',
    'setReservationPeople',
    'setReservationShowSearchButton',
    'setReservationRecommended',
    'setReservationVendors',
    'setReservationAvailability',
    'setReservationAvailabilityVendor',
    'setGallery',
    'setSource',
    'setServices',
    'setSendReservation',
    'setIdCity',
    'setIsSearch',
    'setSelectedZone',
    'setSelectedDate',
    'setIsAvalability'
  ]
});
