import Vue from 'vue';
import Vuex from 'vuex';
import authModule from '@/modules/auth';
import precomproModule from '@/modules/precompro';
import Api from '@/helpers/api';
import globalTypes from '@/types/global';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    online: null,
    language: 'es',
    isMobile: false,
    width: null,
    height: null,
    selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime()
  },
  actions: {
    [globalTypes.actions.getTimeAtention]: ({ commit }, inf) => {
      return new Promise((resolve, reject) => {
        Api().get('precompro/timeAtention?date=' + inf.data.date)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  getters: {
    [globalTypes.getters.language]: state => state.language,
    [globalTypes.getters.online]: state => state.online,
    [globalTypes.getters.width]: state => state.width,
    [globalTypes.getters.height]: state => state.height,
    [globalTypes.getters.isMobile]: state => state.isMobile,
    [globalTypes.getters.selectedDate]: state => state.selectedDate
  },
  mutations: {
    [globalTypes.mutations.setLanguage] (state, lang) {
      state.language = lang;
    },
    [globalTypes.mutations.setIsMobile] (state, mobile) {
      state.isMobile = mobile;
    },
    [globalTypes.mutations.setOnline] (state, status) {
      state.online = status;
    },
    [globalTypes.mutations.setWidth] (state, width) {
      state.width = width;
    },
    [globalTypes.mutations.setHeight] (state, height) {
      state.height = height;
    },
    [globalTypes.mutations.setSelectedDate] (state, date) {
      state.selectedDate = date;
    }
  },
  modules: {
    authModule,
    precomproModule
  }
});
