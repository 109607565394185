import axios from 'axios';
import LocalStorage from 'store';

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_URL + '/api/',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorage.get('precompro_token')
    }
  });
};
