import authTypes from '@/types/auth';
import Api from '@/helpers/api';
import { Base64 } from 'js-base64';

const state = {
  user: [], // datos del usuario
  payment: [], // datos de tarjetas
  logged: null, // si esta logueado
  userId: null, // id usuario
  typeModal: '', // habilita si es login , register o reset
  showAuth: false // mustra modal de autenticacion
};

const actions = {
  [authTypes.actions.login]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/login', userInput)
        .then(user => {
          if (parseInt(user.data.code) !== 400 && parseInt(user.data.code) !== 403 && parseInt(user.data.code) !== 404) {
            window.localStorage.setItem('precompro_token', user.data.token);
            window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(user.data.user)));
            window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(user.data.payments)));
            window.localStorage.setItem('precompro_userId', user.data.userId);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setPayment);
            commit(authTypes.mutations.setUserId);
            commit(authTypes.mutations.setPayment);
          }
          resolve(user.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.socialLogin]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/socialLogin', data)
        .then(user => {
          if (parseInt(user.data.code) !== 400 && parseInt(user.data.code) !== 403 && parseInt(user.data.code) !== 404) {
            window.localStorage.setItem('precompro_token', user.data.token);
            window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(user.data.user)));
            window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(user.data.payments)));
            window.localStorage.setItem('precompro_userId', user.data.userId);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setUserId);
            commit(authTypes.mutations.setPayment);
          }
          resolve(user.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.register]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/register', userInput)
        .then(user => {
          if (parseInt(user.data.code) !== 400 && parseInt(user.data.code) !== 403 && parseInt(user.data.code) !== 404) {
            window.localStorage.setItem('precompro_token', user.data.token);
            window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(user.data.user)));
            window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(user.data.payments)));
            window.localStorage.setItem('precompro_userId', user.data.userId);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setUserId);
            commit(authTypes.mutations.setPayment);
          }
          resolve(user.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.reset]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/reset', userInput)
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.validateToken]: (state, token) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/validateToken/' + token)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.changedPassword]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/updatePassword', data)
        .then(response => {
          if (parseInt(response.data.code) === 200) {
            window.localStorage.setItem('precompro_user', btoa(JSON.stringify(response.data.user)));
            commit(authTypes.mutations.setUser);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.logout]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/logout', user)
        .then(user => {
          window.localStorage.removeItem('precompro_token');
          window.localStorage.removeItem('precompro_user');
          window.localStorage.removeItem('precompro_payment');
          window.localStorage.removeItem('precompro_userId');
          commit(authTypes.mutations.setUser);
          commit(authTypes.mutations.setPayment);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.updateUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/update', data)
        .then(response => {
          window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(response.data.user)));
          window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify(response.data.payments)));
          commit(authTypes.mutations.setUser);
          commit(authTypes.mutations.setPayment);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.refreshUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Api().post('auth/refreshUser', data.data)
        .then(response => {
          if (response.data.code === 200) {
            window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(response.data.user)));
            commit(authTypes.mutations.setUser);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  [authTypes.getters.showAuth]: (state) => {
    return state.showAuth;
  },
  [authTypes.getters.typeModal]: (state) => {
    return state.typeModal;
  },
  [authTypes.getters.user]: (state) => {
    return state.user;
  },
  [authTypes.getters.payment]: (state) => {
    return state.payment;
  },
  [authTypes.getters.userId]: (state) => {
    return state.userId;
  },
  [authTypes.getters.logged]: (state) => {
    return state.logged;
  }
};

const mutations = {
  [authTypes.mutations.setShowAuth]: (state, status) => {
    state.showAuth = status;
  },
  [authTypes.mutations.setTypeModal]: (state, type) => {
    state.typeModal = type;
  },
  [authTypes.mutations.setUser]: (state) => {
    if (window.localStorage.getItem('precompro_user')) {
      const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_user')));
      state.user = json;
      state.logged = true;
    } else {
      state.logged = false;
      state.user = [];
    }
  },
  [authTypes.mutations.setPayment]: (state) => {
    if (window.localStorage.getItem('precompro_user')) {
      const json = JSON.parse(Base64.decode(window.localStorage.getItem('precompro_payment')));
      state.payment = json;
      state.logged = true;
    } else {
      state.logged = false;
      state.payment = [];
    }
  },
  [authTypes.mutations.setUserId]: (state) => {
    if (window.localStorage.getItem('precompro_userId')) {
      state.userId = window.localStorage.getItem('precompro_userId');
    }
  },
  [authTypes.mutations.setLogged]: (state, logged) => {
    if (window.localStorage.getItem('precompro_token')) {
      state.logged = true;
    } else {
      state.logged = false;
      state.user = '';
    }
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
