import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Vue2Filters from 'vue2-filters';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import LocalStorage from 'store';
// import Buefy from 'buefy'
import VeeValidate from 'vee-validate';
import VeeValidateEs from '@/helpers/lang/es';
import VeeValidateEn from '@/helpers/lang/en';
import i18n from '@/helpers/i18n';
import Notifications from 'vue-notification';
import VueMeta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
import vueAwesomeSwiper from 'vue-awesome-swiper';
import mxGlobal from '@/mixins/global';
import VueTheMask from 'vue-the-mask';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

import 'swiper/dist/css/swiper.css';
// import 'buefy/dist/buefy.css'
import 'moment/locale/es';

moment.tz.setDefault('America/Bogota');
// Vue.use(Buefy)
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(vueAwesomeSwiper);
Vue.use(Vue2Filters);
Vue.use(VeeValidate, {
  locale: LocalStorage.get('lang') || 'es',
  dictionary: {
    es: VeeValidateEs,
    en: VeeValidateEn
  }
});
Vue.use(VueMoment, {
  moment
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBEiuJ8FGGFTuBE3qKCw8oFiycGuVVLvSc'
  }
});
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.mixin(mxGlobal);
Vue.mixin(Vue2Filters.mixin);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
