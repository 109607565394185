import namespace from '@/utils/namespace';

export default namespace('global', {
  actions: [
    'getTimeAtention'
  ],
  getters: [
    'language',
    'online',
    'width',
    'isMobile',
    'height',
    'selectedDate'
  ],
  mutations: [
    'setLanguage',
    'setOnline',
    'setIsMobile',
    'setWidth',
    'setHeight',
    'setSelectedDate'
  ]
});
